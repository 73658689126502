import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Modal } from 'antd';
import type { BaseData, IMainSubStructureModel, NormalProgrammeParams, PaginationData } from 'egenie-utils';
import { ExportStore, request, SearchListModal } from 'egenie-utils';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React from 'react';
import api from '../../utils/api';
import type { GalleryArea, Gallery } from './types';

export class GalleryStatisticsStore {
  constructor() {
    this.initPage();
  }

  public exportStore = new ExportStore({ parent: this });

  private initPage = (): void => {
    this.queryGallery();
  };
  
  private onQuery = async(params): Promise<unknown> => {
    const { filterParams, ...rest } = params;
    const tempParams = this.handleFilterParams(filterParams);
    const res: PaginationData<unknown> = await request({
      url: api.queryGalleryGoods,
      method: 'POST',
      data: {
        ...tempParams,
        ...rest,
      },
    });

    if (res?.data) {
      const temp = {
        ...res,
        data: {
          ...res.data,
          list: res.data.list?.map((el) => {
            return {
              ...el as object,
              id: nanoid(5),
            };
          }),
        },
      };
      return Promise.resolve(temp);
    } else {
      return Promise.resolve(null);
    }
  };

  private handleFilterParams = (filterParams) => {
    if (filterParams?.date) {
      const [
        startTime,
        endTime,
      ] = filterParams.date.split(',');
      Object.assign(filterParams, {
        startTime,
        endTime,
      });
      delete filterParams.date;
      return filterParams;
    }
    return {};
  };

  // 导出
  public onClickExport = (): void => {
    const selectedIds = Array.from(this.searchListStore.grid.gridModel.selectedIds);
    const queryParamTranslate = this.searchListStore.programme.filterItems.translateParams.join(' ');
    const params = this.handleFilterParams(this.searchListStore.programme.filterItems.params);
    const fileName = '展厅数据概览';
    const exportType = 'boss_statistics_gallery_goods';
    Modal.confirm({
      title: '提示',
      content: '是否导出查询的数据?',
      onOk: () => {
        this.exportStore.onShow(fileName, exportType, '', {}, queryParamTranslate, params); // ids不传即代表导出全部数据
      },
    });
  };

  /* 请求展厅 */
  private queryGallery = async(): Promise<void> => {
    const res: BaseData<Gallery[]> = await request({ url: api.findAllGallery });
    if (res?.data) {
      const tempOptions = res.data.map((el) => {
        return {
          label: el.galleryName,
          value: `${el.galleryId}`,
        };
      });
      this.searchListStore.programme.filterItems.updateFilterItem([
        {
          type: 'select',
          field: 'galleryId',
          data: tempOptions,
          value: tempOptions[0].value ?? undefined,
        },
      ]);
    }
  };

  private programme: Partial<NormalProgrammeParams> = {
    count: 6,
    filterItems: [
      {
        type: 'select',
        label: '展厅',
        field: 'galleryId',
        required: true,
      },
      {
        type: 'dateRange',
        label: '日期',
        field: 'date',
        allowClear: false,
        required: true,
        startTime: moment().subtract(29, 'day'),
        format: 'YYYY-MM-DD',
        endTime: moment(),
      },
    
    ],
  };
  
  private gridModel: IMainSubStructureModel = {
    buttons: [
      {
        text: '导出',
        handleClick: this.onClickExport,
      },
    ],
    grid: {
      columns: [
        {
          key: 'time',
          name: '日期',
          width: 200,
        },
        {
          key: 'totalGoodsNum',
          name: (
            <Tooltip title="展厅上架状态为已上架且展厅有库存的款式数量">
              <span>
                款式总数
              </span>
              {'  '}
              <InfoCircleOutlined/>
            </Tooltip>
          ),
          width: 120,
        },
        {
          key: 'vendorNum',
          name: '供应商数',
          width: 120,
        },
        {
          key: 'putAwayNum',
          name: '上架款数',
          width: 120,
        },
        {
          key: 'soldOutNum',
          name: '下架款数',
          width: 120,
        },
        {
          key: 'sampleOrderGoodsNum',
          name: '下单款数',
          width: 120,
        },
        {
          key: 'translateRate',
          name: '款式交易转化',
          width: 120,
        },
        {
          key: 'saleGoodsNum',
          name: '当日动销款',
          width: 120,
        },
        {
          key: 'saleCount',
          name: '当日款式销量',
          width: 120,
        },
        {
          key: 'saleAmount',
          name: '当日款式销售额',
        },
      ].map((el) => {
        return {
          ...el,
          resizable: true,
          sortable: false,
        };
      }),
      showCheckBox: false,
      primaryKeyField: 'id',
      showEmpty: true,
      rowHeight: 56,
    },
    api: {
      onQuery: (params) => {
        return this.onQuery(params);
      },
    },
  };

  public searchListStore = new SearchListModal({
    programme: this.programme,
    grid: this.gridModel,
  });
}
