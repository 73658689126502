import { ExportModal, SearchListStructure } from 'egenie-utils';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { GalleryStatisticsStore } from './store';

const LackStockStatistics = observer((props) => {
  const store = useMemo(() => new GalleryStatisticsStore(), []);
  const { searchListStore, exportStore } = store;
  return (
    <>
      <SearchListStructure store={searchListStore}/>
      <ExportModal store={exportStore}/>
    </>
  );
});

export default LackStockStatistics;
